import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, child, get, set, update } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyDQNHb2qJAW_MCecvMMQKKhC6qI2iQ6NyM",
    authDomain: "usth-caas.firebaseapp.com",
    databaseURL: "https://usth-caas-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "usth-caas",
    storageBucket: "usth-caas.appspot.com",
    messagingSenderId: "893818737371",
    appId: "1:893818737371:web:5c1e83cc9dc69ca2d8b2fe",
    measurementId: "G-SQ08F922G3"
  };
  
const app = initializeApp(firebaseConfig);
var vdata="none"

export function getQuestionDetails(callback, usr , id){
    const dbRef = ref(getDatabase());
    console.log(`users/${usr}/questions/${id}`)
    get(child(dbRef, `users/${usr}/questions/${id}`)).then((snapshot) => {
    if (snapshot.exists()) {
        console.log(snapshot.val());
        callback(snapshot.val());
    } else {
        console.log("No data available");
    }
    }).catch((error) => {
    console.error(error);
    });
}

export function getUserUpdate(callback, id){
    const db = getDatabase();
    const usrRef = ref(db, 'users/' + id);
    onValue(usrRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        callback(data)
    });
}

//Function to read users from database
export function readUsersOnce(callback){
    const dbRef = ref(getDatabase());
    get(child(dbRef, `users`)).then((snapshot) => {
    if (snapshot.exists()) {
        console.log(snapshot.val());
        callback(snapshot.val());
    } else {
        console.log("No data available");
    }
    }).catch((error) => {
    console.error(error);
    });
}

export function updateAnswer(callback,id,question,answer){
    const db = getDatabase();
    try{

        // update(ref(db, `users/${id}/questions/${question}`), {
        //     qans: answer
        // });

        update(ref(db, `users/${id}`), {
            message: answer
        });

        callback("Saved your fighter!")
    }
    catch (e){
        console.log(e)
        callback("Error happened!");
    }
}