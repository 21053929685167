import React, {Component} from 'react';

import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"

import FighterInfo from './detail';
import { readUsersOnce } from '../Utils/database';

import axios from 'axios';

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    FormSelect,
    Container,
    Row,
    Col,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormInput,
    Collapse,
    Button
} from "shards-react";



export default class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleNavbar = this.toggleNavbar.bind(this);

        this.state = {
            dropdownOpen: false,
            collapseOpen: false,
            selectedFighter: "Aiden",
            showFighter: false,
            users: []
        };

        //Do this to able to set state inside functions
        this.processUser = this.processUser.bind(this);

    }

    //Function to process users read from firebase
    processUser(data){
        let parent_key = Object.keys(data);
        console.log(parent_key);
        this.setState({
            ... {
                users: parent_key,
                selectedFighter: parent_key[0]
            }
        })
    }

    componentDidMount() {
        //Read users
        readUsersOnce(this.processUser);
        
        // var usersGet=[];
        // axios.get(`https://kelly-cents-cigarettes-desired.trycloudflare.com/get_by/users`)
        //   .then(res => {
        //     usersGet = res.data.data;
        //     console.log(usersGet);
        //     this.setState({
        //         ... {
        //             users: usersGet,
        //             selectedFighter: usersGet[0]
        //         }
        //     })
        //   })
        //   .catch(error => console.log(error));
      }
  
    toggleDropdown() {
      this.setState({
        ...this.state,
        ...{
          dropdownOpen: !this.state.dropdownOpen
        }
      });
    }
  
    toggleNavbar() {
      this.setState({
        ...this.state,
        ...{
          collapseOpen: !this.state.collapseOpen
        }
      });
    }

    handleDrop(e) {
        console.log("Updated to " + e.target.value);

        this.setState({
            ...this.state,
            ...{
                selectedFighter: e.target.value
            }
        });
    }
  
    render() {
        const normalHome = (
            <div>
                <div style={{ margin: "100px"}}></div>
                    <h2>Select your fighter</h2>
                    <div style={{ margin: "100px"}}></div>
                    <Container style={{ display: "flex", justifyContent: "space-between" }}>
                        {/* Dropdown to select user */}
                        <Row>
                            <FormSelect size="sm" className="mb-2"
                                onChange={(e) => {
                                    //Selected fighter
                                    this.handleDrop(e)
                                }}
                            >
                                {
                                    this.state.users.map(function(e,id){
                                        return(<option value={e}>{e}</option>)
                                    })
                                }
                                
                               
                            </FormSelect>
                            <Button theme="success"
                                onClick={()=>{
                                    // if (this.state.selectedFighter != "Select Fighter")
                                        this.setState({
                                            showFighter: true
                                        })
                                }}
                            >Go</Button>
                        </Row>
                    </Container>
            </div>
        )

        const showInfo = (
            <div>
                    <div style={{ margin: "100px"}}></div>
                    <h2>Fighter info</h2>
                    <div style={{ margin: "100px"}}></div>
            </div>
        )

        return (
            <div>
                <Navbar type="dark" theme="primary" expand="md">
                    <NavbarBrand href="#">CAAS Controller Dashboard</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} />
            
                    <Collapse open={this.state.collapseOpen} navbar>
                        <Nav navbar>
                        <NavItem>
                            <NavLink active href="/">
                                Home
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="https://yitec.net" navbar>
                                YITEC
                            </NavLink>
                        </NavItem>
                        
                        </Nav>
            
                        <Nav navbar className="ml-auto">
                        <InputGroup size="sm" seamless>
                            <InputGroupAddon type="prepend">
                            <InputGroupText>
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroupText>
                            </InputGroupAddon>
                            <FormInput className="border-0" placeholder="Search..." />
                        </InputGroup>
                        </Nav>
                    </Collapse>
                    </Navbar>
                {this.state.showFighter ? (<FighterInfo id={this.state.selectedFighter}></FighterInfo>) : normalHome}
            </div>
        );
    }
  }
