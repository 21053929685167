import React from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardImg,
  CardBody,
  CardFooter,
  Button,
  FormInput
} from "shards-react";
import axios from 'axios';
import { getUserUpdate, getQuestionDetails,updateAnswer } from "../Utils/database";

export default class FighterInfo extends React.Component  {

  constructor(props){
    super(props)
    this.state = {
        FighterName: props.id,
        FighterImage: "https://place-hold.it/1200x600",
        FighterQuestion: null,
        FighterAnswer: null,
        FighterStatus: null,
        FighterURL: null
    }

    //to call setstate in process Fighter
    this.processFighter = this.processFighter.bind(this);
    this.processQuestion = this.processQuestion.bind(this);
  }
  
  processQuestion(data){
    this.setState ({
        ...{
            
            FighterImage: data.img,
        }
    });
    
    
  }

  processFighter(data){

    console.log("Printing data")
    console.log(data.questions)

    let qKeys = Object.keys(data.questions)
    console.log(qKeys)

    getQuestionDetails(this.processQuestion, this.state.FighterName, qKeys[qKeys.length-1]);
    
    
    // let usrStatus = data.status;
    // console.log(usrStatus);

    // //Sort
    // usrdata.sort(function(y, x){
    //     return x.timestamp - y.timestamp;
    // })


    this.setState ({
        ...{
            FighterQuestion: qKeys[qKeys.length-1],
            FighterStatus: data.status,
            FighterURL: data.url
            
        }
            
    });

  }

  componentDidMount() {

    getUserUpdate(this.processFighter,this.state.FighterName);

    

  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render(){
    
    return (
        <Card style={{ maxWidth: "100%" }}>
          <CardHeader>Fighter Info</CardHeader>
          <CardImg src={this.state.FighterImage} />
          <CardBody>
            <CardTitle>{this.state.FighterName}</CardTitle>
            <p><strong>Fighter status: </strong> {this.state.FighterStatus}</p>
            <p><strong>Fighter current URL: </strong> {this.state.FighterURL}</p>
            <p>Help this fighter find his answer</p>
            <FormInput 
            onChange={(e)=>{
                this.setState({
                    ...{
                        FighterAnswer: e.target.value
                    }
                })
            }}
            placeholder="Submit answer" />
            <div style={{margin: "20px"}}></div>
            <Button onClick={()=>{
                this.setState({
                    ...{
                        message: "Saving your fighter..."
                    }
                })
                
                updateAnswer(()=>{

                },this.state.FighterName,this.state.FighterQuestion,this.state.FighterAnswer)
               
                
            }}>Submit answer &rarr;</Button>
          </CardBody>
          <CardFooter>{this.state.message}</CardFooter>
        </Card>
      );
  }
}